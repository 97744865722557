(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name cancellations.postponeCanceledMatches.controller:PostponeCanceledMatchesCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.cancellations.canceledMatches.postponeCanceledMatches')
    .controller('PostponeCanceledMatchesCtrl', PostponeCanceledMatchesCtrl);

  function PostponeCanceledMatchesCtrl($state, $mdToast, $filter, Postponements, LastCancellation, entities, facilities, match) {
    var vm = this;
    vm.ctrlName = 'PostponeCanceledMatchesCtrl';
    vm.informationRequired = false;
    vm.facilities = angular.copy(facilities);
    vm.postponement = {
      date: match.date,
      time: _.isUndefined(match.time) ? undefined : moment(match.time, 'HH:mm').toDate(),
      facility: match.facility === null ? undefined : match.facility
    };

    vm.localTeam = match.localTeam;
    vm.localTeam.columnName = 'CHAMPIONSHIPS.LOCAL_TEAM';

    vm.visitorTeam = match.visitorTeam;
    vm.visitorTeam.columnName = 'CHAMPIONSHIPS.VISITOR_TEAM';

    vm.matchDate = moment(match.date, "DD/MM/YYYY").toDate();
    vm.canSetTime = checkCanSetTime();
    vm.canSetFacility = checkCanSetFacility();

    vm.savePostponement = function savePostponement() {
      var postObject = {};
      postObject.date = moment(vm.postponement.date).format('YYYY-MM-DD');
      postObject.time = angular.isUndefined(vm.postponement.time) || !checkCanSetTime() ? null : moment(vm.postponement.time).format('HH:mm');
      postObject.facilityId = !checkCanSetFacility() ? null : vm.postponement.facility;
      postObject.details = vm.postponement.details;

      Postponements.save({id: match.id}, postObject, function () {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('POSTPONEMENTS.SUCCESS_CREATION'))
            .position('bottom left')
            .hideDelay(3000)
        );
        $state.go('home.cancellations.canceledMatches', {}, {reload: true});
      }, function (error) {
        var message;
        if (error.status === 400) {
          message = 'POSTPONEMENTS.' + error.data.message;
        } else {
          message = 'SERVER_ERROR';
        }
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    };

    function checkCanSetTime() {
      var item = findItemById(entities, vm.localTeam.entityId);
      return angular.isDefined(item);
    }

    function checkCanSetFacility() {
      return vm.canSetTime;
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
